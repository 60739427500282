import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2116fb5e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "caodi"
};
const _hoisted_2 = {
  class: "docker",
  ref: "scrollContainer",
  style: {
    "font-size": "0.56rem",
    "color": "#A6ACB4",
    "text-align": "center",
    "line-height": "100vh"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_dropdown_item = _resolveComponent("van-dropdown-item");
  const _component_van_dropdown_menu = _resolveComponent("van-dropdown-menu");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_Footer = _resolveComponent("Footer");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: $data.lang.u1,
    onClickLeft: _cache[1] || (_cache[1] = $event => $options.goto('user'))
  }, {
    left: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "chat-o",
      size: "18"
    })]),
    right: _withCtx(() => [_createVNode(_component_van_dropdown_menu, null, {
      default: _withCtx(() => [_createVNode(_component_van_dropdown_item, {
        modelValue: $data.select_lang.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.select_lang.value = $event),
        options: $data.lang_list,
        onChange: $options.select
      }, null, 8, ["modelValue", "options", "onChange"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["title"]), _createElementVNode("div", _hoisted_2, " To be opened…… ", 512), _createVNode(_component_Footer, {
    lang_list: $data.lang_list,
    lang: $data.lang,
    type: "0",
    dsq: "0",
    time: "0"
  }, null, 8, ["lang_list", "lang"])])]);
}